import * as React from "react";
import { Link, navigate } from "gatsby";
import {
    setUser,
    setJWT,
    getRedirectTo,
    setRedirectTo,
} from "../../../services/auth";
import { useQueryParam, StringParam } from "use-query-params";
import axios from "axios";

export default () => {
    const [accessToken] = useQueryParam("access_token", StringParam);

    React.useEffect(() => {
        axios
            .get(
                `${process.env.GATSBY_STRAPI_BACKEND_URL}/auth/google/callback`,
                {
                    params: new URLSearchParams([
                        ["access_token", accessToken],
                    ]),
                }
            )
            .then((res) => {
                const { jwt, user } = res.data;
                if (jwt && user) {
                    setJWT(jwt);
                    setUser(JSON.stringify(user));

                    const redirectTo = getRedirectTo();
                    if (redirectTo) {
                        setRedirectTo("");
                        navigate(redirectTo);
                    } else {
                        navigate("/");
                    }
                } else {
                    console.log("Error: Cannot get jwt and user data");
                }
            })
            .catch((err) => console.log(err));
    }, [accessToken]);

    return (
        <div>
            We will redirect you soon...{" "}
            <Link to="/">Click this link if it's take longer than 5 sec.</Link>
        </div>
    );
};
